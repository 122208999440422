import { createSlice } from "@reduxjs/toolkit";

export const configSlice = createSlice({
  name: "config",
  initialState: {
    // appKey: '0134938397',
    // ctxPath: "http://192.168.0.101:39100",
    // imgPath: "http://192.168.0.101:39100/uploads",
    // appKey: '1599887751',
    // ctxPath: "http://47.113.230.59:39100",
    // imgPath: "http://47.113.230.59:39100/uploads",
    // keyboardEditer: "https://h5.clgm666.site/adminEditer",
    appKey: '0134938397',
    ctxPath: "https://server.ruyungame.com",
    imgPath: "https://server.ruyungame.com/uploads",
    keyboardEditer: "https://play.ruyungame.com/adminEditer",
    done: false,
    clientType: 'Admin',
    version: '1.0.0',
    maintain: false, //是否正在维护
  },
  reducers: {
    initConfig: (state) => {
      state.done = true;
    },
    setMaintain: (state, params) => {
      state.maintain = params.payload.data;
    },
    setCtxPath: (state, params) => {
      state.ctxPath = params.payload;
    },
  },
});

export const { initConfig, setMaintain, setCtxPath } = configSlice.actions;

export default configSlice.reducer;
